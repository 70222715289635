import React, { useEffect, useState } from 'react';
import img1 from '../assets/img1.png';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import image1 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';
import wait from '../assets/wait.png';
import confetti from 'canvas-confetti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



function Home() {
  const [currentGoodIndex, setCurrentGoodIndex] = useState(null);
  const [currentBedIndex, setCurrentBedIndex] = useState(null);
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const correctPassword = 'Amelia0512';
  const linkToDrive = 'https://drive.google.com/drive/folders/1IW6h190EM3PkESfHUeQg4kZJ4mMClYdp?usp=drive_link';

  const checkPassword = () => {
    if (password === correctPassword) {
      setAuthenticated(true);
      window.open(linkToDrive, '_blank');
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };


  const imagesGood = [img3, img1, img2];
  const imagesBed = [image3, image1, image2];

  const handleGoodButtonClick = () => {
    setCurrentBedIndex(null);
    setCurrentGoodIndex((prevIndex) => (prevIndex + 1) % imagesGood.length);

    const duration = 3 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function() {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);

      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);

  };

  const handleBedButtonClick = () => {
    setCurrentGoodIndex(null);
    setCurrentBedIndex((prevIndex) => (prevIndex + 1) % imagesBed.length);
  };

  const formatDateForGoogleMaps = (date) => {
    // Format the date as YYYY-MM-DD
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  };

  const date = new Date(2024, 4, 12); // 12th May 2024
  const formattedDate = formatDateForGoogleMaps(date);


  return (
    <>
    <div className="container d-flex justify-content-center">
        <div className="row d-flex justify-content-center align-items-center text-center flex-grow-1 pt-5">
          <div className="col-12">
            <h1 style={{ fontSize: '2.6rem' }}>Ci sarai al mio battesimo?</h1>
          </div>
          <img src={
                    currentGoodIndex !== null ? (
                      imagesGood[currentGoodIndex]
                  ) : currentBedIndex !== null ? (
                      imagesBed[currentBedIndex]
                  ) : wait
                  }
                  style={{ width: '100%', backgroundSize: 'contain', backgroundPosition: 'center'}}
                  className='img-fluid mb-5' 
          />
          <div className="col-6 text-center">
            <button className="rounded-pill shadow text-uppercase px-4 fs-1 btn bg-danger bg-opacity-50 text-light" onClick={handleBedButtonClick}>
              No 💔
            </button>
          </div>
          <div className="col-6 text-center">
            <button className="rounded-pill shadow text-uppercase px-4 fs-1 btn bg-info text-light bg-opacity-50" onClick={handleGoodButtonClick}>
              Si ❤️
            </button>
          </div>
        </div>
    </div>
    <div className='container'>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-11 text-start mt-5'>
              <p className='text-center text-light fs-1 text-uppercase'>Info evento:</p>
              <ul>
              <li className='mb-3'>
                <div className='col d-flex justify-content-start'>
                  <div className='col-2'>
                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" className='me-2 fs-1 pink' />
                  </div>
                  <div className='col-10'>
                    <a className='text-light text-decoration-none' href={`https://calendar.google.com/?date=${formattedDate}`}>12/05/2024 12:00 p.m.</a>
                  </div>
                </div> 
              </li>
                <li className='mb-3'>
                <div className='col d-flex justify-content-start'>
                  <div className='col-2'>
                    <FontAwesomeIcon icon="fa-solid fa-location-dot"  className='me-2 fs-1 pink'/>          
                  </div>
                  <div className='col-10'>
                   <a className='text-light text-decoration-none' href="https://maps.google.com/?q=Abazia+di+Santa+Maria+Assunta,+Via+Don+G.+bosco+74,+Mogliano+Veneto+(TV)">Abazia di Santa Maria Assunta, Via Don G. bosco 74, Mogliano Veneto (TV)</a>
                  </div>
                </div> 
                </li>
              </ul>
            </div>
        </div>
    </div>
    <div className='container mb-5 pb-5'>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-11 text-start mt-5'>
              <p className='text-center text-light fs-1 text-uppercase'>Foto Battesimo</p>
              {!authenticated && (
                <div className='form-group mt-3 text-center'>
                  <input
                    type='password'
                    className='form-control'
                    placeholder='Password'
                    id='password'
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <button className="rounded-pill shadow text-uppercase px-4 fs-5 mt-4 btn bg-light text-dark text-light"onClick={checkPassword}>Invia</button>
                </div>
              )}

          </div>
        </div>
    </div>
    </>
  );
    
}

export default Home;
